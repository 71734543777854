import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const AnimateOnUpdate = ({ updateKey, isSpan, children, className, ...props }) => {
  const componentProps = {
    key: String(updateKey),
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      ease: 'linear',
      duration: 0.4,
    },
    className: cx(className),
    ...props,
  }
  return (
    <AnimatePresence exitBeforeEnter>
      {isSpan ? (
        <motion.span {...componentProps}>{children}</motion.span>
      ) : (
        <motion.div {...componentProps}>{children}</motion.div>
      )}
    </AnimatePresence>
  )
}

export default AnimateOnUpdate
