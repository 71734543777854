import React, { useEffect, useState } from 'react'

// Components
import AnimateOnUpdate from '../components/animate-on-update'
import Arrow from '../components/arrow'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { worksData } from '../components/works/presets'

// Assets

const Profile = () => {
  const [selectedCategory, setSelectedCategory] = useState('featured')
  const [filteredItems, setFilteredItems] = useState(worksData)
  const [selectedIndex, setSelectedIndex] = useState(0)

  // const listenSelect = () => {
  //   const works = document.querySelectorAll('.work-item')
  //   const workSamples = document.querySelectorAll('.work-sample')
  //   works.forEach((e) => {
  //     e.addEventListener('mouseover', function () {
  //       changeSelectedWork(e, works, workSamples)
  //     })
  //   })
  // }

  // const changeSelectedWork = (e, works, workSamples) => {
  //   const index = e.dataset.index
  //   workSamples.forEach((e) => {
  //     e.classList.remove('selected')
  //   })
  //   works.forEach((e) => {
  //     e.classList.remove('selected')
  //   })
  //   e.classList.add('selected')
  //   workSamples[index].classList.add('selected')
  // }

  const handleSelect = (index) => setSelectedIndex(index)

  const handleChangeCategory = (e) => {
    const category = e.target.innerText.toLowerCase()
    if (selectedCategory === category) return
    setSelectedCategory(category)
  }

  useEffect(() => {
    setFilteredItems(
      worksData.filter((work) => {
        if (selectedCategory === 'all') return true
        return work.featured
      })
    )
    setSelectedIndex(0)
  }, [selectedCategory])

  return (
    <>
      <Layout page="works">
        <SEO title="Works" />
        <section className="work-showcase">
          <div className="category-selection">
            <button
              onClick={handleChangeCategory}
              className={selectedCategory !== 'all' ? 'selected' : ''}
            >
              Featured
            </button>
            <button
              onClick={handleChangeCategory}
              className={selectedCategory === 'all' ? 'selected' : ''}
            >
              All
            </button>
          </div>
          <AnimateOnUpdate updateKey={selectedCategory} className="left-column--half">
            {filteredItems.map((work, index) => (
              <div
                className={`work-item ${work.featured ? 'featured' : ''} ${
                  index === selectedIndex ? 'selected' : ''
                }`}
                data-index={index}
                onMouseOver={() => handleSelect(index)}
              >
                <a
                  className="work-item--details"
                  href={work.link}
                  target="_blank"
                  rel="noreferrer"
                  cursor-class="select-inverted"
                >
                  <time>{work.year}</time>
                  <span>{work.title}</span>
                  <div className="tags">
                    {work.tags.map((tag) => (
                      <span className={tag.toLowerCase().replace(/\./g, '-')}>{tag}</span>
                    ))}
                  </div>
                  <Arrow />
                </a>
              </div>
            ))}
          </AnimateOnUpdate>
          <AnimateOnUpdate updateKey={selectedCategory} className="right-column--half">
            {filteredItems.map((work, index) => (
              <div className={`work-sample ${index === selectedIndex ? 'selected' : ''}`}>
                <a href={work.link} target="_blank" rel="noreferrer" cursor-class="select">
                  <picture className="work-sample--image">
                    <img src={work.image} alt={`${work.title} website`} />
                  </picture>
                </a>
              </div>
            ))}
          </AnimateOnUpdate>
        </section>
      </Layout>
    </>
  )
}

export default Profile
