import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      space="preserve"
    >
      <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
    </svg>
  );
};

export default Arrow;
